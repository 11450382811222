import { IProductPrice } from 'api/product_sdk';
import { EPriceType } from './constants';
import _ from 'lodash';

export const validateProductPrices = ({
  productPrices,
  priceType,
  setErrorCallback,
  isEarlyCheckin = false,
  isLateCheckout = false,
}: {
  productPrices: Array<IProductPrice>;
  priceType: EPriceType;
  setErrorCallback: (name: string, error: { type: string; message: string }) => void;
  isEarlyCheckin?: boolean;
  isLateCheckout?: boolean;
}): boolean => {
  let hasErrors = false;

  if (priceType === EPriceType.MULTIPLE) {
    if (!isEarlyCheckin && !isLateCheckout) {
      productPrices.forEach(({ label }, index) => {
        if (_.isEmpty(label)) {
          setErrorCallback(`product.productPrices.${index}.label`, {
            type: 'custom',
            message: 'Label is required',
          });
          hasErrors = true;
        }
      });
    }

    if (isEarlyCheckin || isLateCheckout) {
      productPrices.forEach(({ timeLabel }, index) => {
        if (!timeLabel) {
          setErrorCallback(`product.productPrices.${index}.timeLabel`, {
            type: 'custom',
            message: 'Please, select a time option',
          });
          hasErrors = true;
        }
      });
    }
  }

  return hasErrors;
};
