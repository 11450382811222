import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Plus as PlusIcon } from 'react-feather';

import {
  Button,
  InputAdornment,
  InputHelperText,
  InputLabel,
  SelectField,
  Spinner,
  TextField,
  Typography,
} from 'ui-kit';
import { calculateYouMake, formatCurrency, getDigitsFromValue } from 'utils';
import { useStore } from 'contexts/store';
import { useOwnerDetails } from 'api/owners';
import { getFieldError } from '../utils';
import { useAuth } from 'contexts/auth/auth';
import { DeleteOutlined } from '@ant-design/icons';
import OwnerProfitTooltip from 'ui-kit/OwnerProfitTooltip';
import { EPriceType, priceTimeLabelOptions, priceTypeOptions } from './constants';
import { Form } from 'antd';
import _ from 'lodash';
import { validateProductPrices } from './utils';
import Stack from 'ui-kit/Stack';

interface Props {
  baseClass: string;
}

const PriceField = ({ baseClass }: Props) => {
  const { control, watch, setValue, setError } = useFormContext();
  const priceType = watch('product.priceType');
  const productPrices = watch('product.productPrices');
  const isEarlyCheckin = watch('product.isEarlyCheckin');
  const isLateCheckout = watch('product.isLateCheckout');

  const { id: ownerId } = useAuth();
  const { data, isLoading } = useOwnerDetails(ownerId);
  const { currency, commissionCutoff, commissionLow, commissionHigh } = useStore();
  const hasPremiumPlan = useMemo(() => data?.plan !== 'BASIC', [data?.plan]);

  const {
    fields,
    append: appendPrice,
    remove: removePrice,
  } = useFieldArray({
    control,
    name: 'product.productPrices',
  });

  const handleChangePriceType = useCallback((value) => {
    const newValue = value;
    setValue('product.priceType', newValue, { shouldValidate: true, shouldDirty: true });

    setValue('product.price', '0.00', { shouldValidate: true, shouldDirty: true });
    if (newValue !== EPriceType.MULTIPLE) {
      setValue('product.productPrices', [], { shouldValidate: true, shouldDirty: true });
    }
  }, []);

  useEffect(() => {
    validateProductPrices({
      productPrices,
      priceType,
      setErrorCallback: setError,
      isEarlyCheckin,
      isLateCheckout,
    });
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Controller
        control={control}
        name="product.priceType"
        render={({ field: { value, onBlur } }) => (
          <SelectField
            fullWidth
            className={`${baseClass}-price-type`}
            options={priceTypeOptions}
            value={value}
            onChange={handleChangePriceType}
            onBlur={onBlur}
            label="Price type"
            defaultValue={EPriceType.STATIC}
            disabled={isEarlyCheckin || isLateCheckout}
          />
        )}
      />
      {priceType === EPriceType.STATIC && (
        <Controller
          control={control}
          name="product.price"
          render={({ field: { value, ref, onChange, ...rest }, fieldState }) => {
            const youMake = calculateYouMake({
              price: value,
              commissionLow,
              commissionHigh,
              commissionCutoff,
              hasPremiumPlan,
            });
            return (
              <div className={`${baseClass}-price`}>
                <TextField
                  required
                  label="Product price"
                  placeholder="0.00"
                  InputProps={{
                    startAdornment: <InputAdornment>$</InputAdornment>,
                  }}
                  inputRef={ref}
                  value={value ?? ''}
                  onChange={(e) => onChange(getDigitsFromValue(e.target.value))}
                  {...getFieldError(fieldState)}
                  {...rest}
                />
                {!hasPremiumPlan && (
                  <div className={`${baseClass}-price-content`}>
                    <div>
                      <InputLabel label="Your estimated profit" /> <OwnerProfitTooltip />
                    </div>
                    <Typography
                      variant="h6"
                      color="secondary"
                      className={`${baseClass}-price-content-value`}
                    >
                      {formatCurrency(youMake, currency)}
                    </Typography>
                  </div>
                )}
              </div>
            );
          }}
        />
      )}
      {priceType === EPriceType.VARIABLE && (
        <InputHelperText>
          Guest sets price, or ask your guest to coordinate price ahead of time (via your product
          description below).
        </InputHelperText>
      )}
      {priceType === EPriceType.MULTIPLE && (
        <div className={`${baseClass}-price-variations`}>
          {fields.map((field, index) => (
            <div key={field.id} className="price-variation">
              {isEarlyCheckin || isLateCheckout ? (
                <Controller
                  control={control}
                  name={`product.productPrices.${index}.timeLabel`}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Form.Item style={{ flex: 1 }}>
                        <SelectField
                          fullWidth
                          className="timeLabel"
                          options={priceTimeLabelOptions}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          errorMessage={error?.message}
                        />
                      </Form.Item>
                    );
                  }}
                />
              ) : (
                <Controller
                  control={control}
                  name={`product.productPrices.${index}.label`}
                  render={({ field: { ref, value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Form.Item style={{ flex: 1 }}>
                        <TextField
                          ref={ref}
                          required
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          errorMessage={error?.message}
                        />
                      </Form.Item>
                    );
                  }}
                />
              )}
              <Controller
                control={control}
                name={`product.productPrices.${index}.price`}
                render={({ field: { ref, value, onChange, onBlur }, fieldState: { error } }) => {
                  const youMake = calculateYouMake({
                    price: value,
                    commissionLow,
                    commissionHigh,
                    commissionCutoff,
                    hasPremiumPlan,
                  });

                  return (
                    <Stack flexDirection="column" style={{ flex: 1 }}>
                      <Form.Item style={{ marginBottom: '8px' }}>
                        <TextField
                          ref={ref}
                          placeholder="0.00"
                          required
                          InputProps={{
                            startAdornment: <InputAdornment>$</InputAdornment>,
                          }}
                          value={value}
                          onChange={(e) => onChange(getDigitsFromValue(e.target.value))}
                          onBlur={onBlur}
                          error={!!error}
                          errorMessage={error?.message}
                        />
                      </Form.Item>
                      {!hasPremiumPlan && value && (
                        <div className={`price-variation-price-content`}>
                          <Typography variant="caption">
                            <div>
                              <InputLabel label="Your estimated profit" /> <OwnerProfitTooltip />
                            </div>
                            <Typography
                              variant="body1"
                              color="secondary"
                              className={`price-variation-price-content-value`}
                            >
                              {formatCurrency(youMake, currency)}
                            </Typography>
                          </Typography>
                        </div>
                      )}
                    </Stack>
                  );
                }}
              />

              <Button variant="outlined" size="small" onClick={() => removePrice(index)}>
                <DeleteOutlined />
              </Button>
            </div>
          ))}
          <Button
            size="small"
            variant="outlined"
            startIcon={<PlusIcon />}
            onClick={() => appendPrice({ label: null, price: '', timeLabel: null })}
          >
            Add price variation
          </Button>
          <Controller
            control={control}
            name="product.productPrices"
            render={({ fieldState: { error } }) => (
              <>
                {error && error.message && (
                  <InputHelperText type="error" showHelperIcon={true}>
                    {error.message}
                  </InputHelperText>
                )}
              </>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default PriceField;
