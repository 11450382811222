import React from 'react';
import StepWizard, { StepWaizardProps } from 'components/StepWizard';
import SelectSubcategory from './SelectSubcategory';
import { InputProduct, ProductCategory, ProductSubcategory } from 'api/types/product';
import SelectProduct from './SelectProduct';
import ProductBaseForm from './ProductBaseForm';
import ProductSettingsBaseForm from './ProductSettingsBaseForm';
import {
  getInitalSettingsBySubcategory,
  parseValuesForRequest,
  validPartialProductSchema,
  validProductSchema,
  validProductSettingsSchema,
  validSubcategorySchema,
  InitialValues,
} from './utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateProductPrices } from './ProductBaseForm/utils';

export type NewProductMode = 'default' | 'quick';

interface Props {
  initialValues?: InitialValues;
  onCancel: () => void;
  onSubmit: (values: InputProduct) => Promise<void> | void;
  onChangeStep: () => void;
  massCreate?: boolean;
  setIsMassCreate?: React.Dispatch<React.SetStateAction<boolean>>;
  mode?: NewProductMode;
}

const defaultInitalValues = {
  subcategory: null,
  product: {
    id: 0,
    store: null,
    price: '',
    description: '',
    instructions: '',
    extraFields: [],
    productPrices: [],
  },
};

const NewProductStepper: React.FC<Props> = ({
  initialValues = defaultInitalValues,
  onChangeStep,
  onSubmit,
  massCreate,
  setIsMassCreate,
  mode = 'default',
}) => {
  const steps: StepWaizardProps<{}>['steps'] = [
    {
      title: 'What type of product would you like to add to your store?',
      step: <SelectSubcategory />,
      resolver: yupResolver(validSubcategorySchema),
      onSubmit: ({ goTo, next }, formCtx) => {
        const { subcategory, product } = formCtx.getValues();
        if (subcategory === ProductSubcategory.SomethingElse) {
          formCtx.setValue('product', {
            ...product,
            subcategory,
          });
          goTo(3);
          return;
        }
        if (subcategory === ProductSubcategory.Link) {
          formCtx.setValue('product', {
            ...product,
            category: ProductCategory.LINK,
            description: '',
            subcategory,
          });
          goTo(3);
          return;
        }
        next();
      },
    },
    {
      title:
        mode === 'quick'
          ? 'Which quick-add product would you like to add to your store?'
          : 'Which product would you like to add?',
      step: <SelectProduct mode={mode} />,
      resolver: yupResolver(validPartialProductSchema),
    },
    {
      title: 'What would you like to tell guests about this product?',
      step: <ProductBaseForm />,
      resolver: yupResolver(validProductSchema),
      onSubmit: ({ next }, formCtx) => {
        const { product } = formCtx.getValues();

        const hasProductPriceErrors = validateProductPrices({
          productPrices: product.productPrices,
          priceType: product.priceType,
          setErrorCallback: formCtx.setError,
          isEarlyCheckin: product.isEarlyCheckin,
          isLateCheckout: product.isLateCheckout,
        });

        if (hasProductPriceErrors) {
          return;
        }

        const {
          product: initialProduct,
          notifyDelegates,
          notifyThreshold,
        } = getInitalSettingsBySubcategory(product.subcategory);

        formCtx.setValue('product', { ...initialProduct, ...product });
        formCtx.setValue('notifyDelegates', notifyDelegates);
        formCtx.setValue('notifyThreshold', notifyThreshold);
        next();
      },
    },
    {
      label: 'Save product',
      title: 'How do you want to manage this product?',
      step: <ProductSettingsBaseForm />,
      resolver: yupResolver(validProductSettingsSchema),
    },
  ];

  const handleSubmit = async ({ product, subcategoryName }: InitialValues) => {
    const values = parseValuesForRequest(product, subcategoryName);
    await onSubmit(values);
  };

  return (
    <StepWizard
      initialStep={1}
      initialValues={initialValues}
      onGoBack={({ goTo, prev, step }, { getValues }) => {
        const { subcategory } = getValues();
        if (
          (subcategory === ProductSubcategory.SomethingElse ||
            subcategory === ProductSubcategory.Link) &&
          step === 3
        ) {
          goTo(1);
          return;
        }
        prev();
      }}
      onChangeStep={onChangeStep}
      onSubmit={handleSubmit}
      steps={mode === 'quick' ? steps.slice(1) : steps}
      massCreate={massCreate}
      setIsMassCreate={setIsMassCreate}
    />
  );
};

export default NewProductStepper;
