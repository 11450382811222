import React from 'react';
import { Select, Option } from 'ui-kit/Select';
import FormControl, { FormControlProps } from 'ui-kit/FormControl/FormControl';
import { SelectProps } from 'antd';
import './SelectField.scss';

interface Props extends SelectProps<Option['value']>, Omit<FormControlProps, 'children'> {
  options: Option[];
}

const SelectField = React.forwardRef<React.RefObject<HTMLSelectElement>, Props>(
  (
    {
      label,
      required,
      helperText,
      error,
      errorMessage,
      fullWidth = false,
      disabled = false,
      options,
      ...rest
    },
    _ref,
  ) => {
    return (
      <FormControl
        label={label}
        required={required}
        helperText={helperText}
        error={error}
        errorMessage={errorMessage}
        fullWidth={fullWidth}
      >
        <Select error={error} options={options} disabled={disabled} {...rest} />
      </FormControl>
    );
  },
);

export default SelectField;
