import { BASE_URL, get, post, useFetch } from 'utils/sdk';
import { Currency } from './types/store';
import { PurchaseStatus } from './types/order';
import { EPriceType } from 'components/NewProductStepper/ProductBaseForm/constants';
import { IProductPrice } from './product_sdk';
import { ProductCategory } from './types/product';

export const addProductToCard = ({
  productId,
  quantity,
  deliveryDate,
  price,
  extraFields,
  note,
  priceType,
  productPriceId,
}: {
  note: string;
  productId: number;
  quantity: number;
  deliveryDate: Date;
  price: number;
  extraFields?: Array<{ productField: number; value: string }>;
  priceType: EPriceType;
  productPriceId?: number;
}) => {
  return post(`${BASE_URL}services/order/add/`, {
    product_id: productId,
    quantity,
    delivery_date: deliveryDate,
    note,
    price,
    price_type: priceType,
    extra_fields: extraFields?.map(({ productField, value }) => ({
      product_field_id: productField,
      value,
    })),
    product_price_id: productPriceId,
  });
};

export const updateOrderProduct = ({
  orderProductId,
  productId,
  quantity,
  deliveryDate,
  price,
  priceType,
  extraFields,
  note,
  productPriceId,
}: {
  orderProductId: number;
  note: string;
  productId: number;
  quantity: number;
  deliveryDate: Date;
  price: number;
  priceType: EPriceType;
  extraFields?: Array<{ productField: number; value: string }>;
  productPriceId?: number;
}) => {
  return post(`${BASE_URL}services/orderproduct/${orderProductId}/update/`, {
    product_id: productId,
    quantity,
    delivery_date: deliveryDate,
    note,
    price,
    price_type: priceType,
    extra_fields: extraFields?.map(({ productField, value }) => ({
      product_field_id: productField,
      value,
    })),
    product_price_id: productPriceId,
  });
};

export interface IGuestCartDetail {
  is_ready: boolean; // return true all purcahses are ready
  has_error: boolean;
  id: number;
  order_number?: string;
  guest_id: number;
  guest_name?: string;
  guest_email?: string;
  guest_phone?: string;
  store_id: number;
  store_name: string;
  currency: Currency;
  is_locked: boolean;
  total_amount: number;
  total_convenience_fees: number;
  guest_total: number;
  order_product_quantity: number;
  order_products: Array<{
    id: number;
    quantity: number;
    price: string;
    amount: number;
    convenience_fee: number;
    guest_amount: number;
    delivery_date: Date;
    note: string;
    is_preorder: boolean;
    product: {
      id: number;
      quantity: number;
      has_quantity: number;
    };
    product_info: {
      product_name: string;
      price: number;
      image: string;
      requires_approval: boolean;
      price_type: EPriceType;
      is_early_checkin: boolean;
      is_late_checkout: boolean;
      product_price?: IProductPrice;
    };
    extra_field_values?: Array<{
      label: string;
      value: string;
      product_field_id: number;
    }>;
    purchase_status?: PurchaseStatus;
    purchase_failure_message?: string | null;
    product_price: IProductPrice | undefined;
  }>;
}

export const useGuestCartDetail = ({ storeId }: { storeId: number }) =>
  useFetch<IGuestCartDetail>(`services/guest/store-cart/${storeId}/detail/`);

export interface IGuestCheckoutStoreSummary {
  id: number;
  guest_id: number;
  guest_name?: string;
  guest_email?: string;
  guest_phone?: string;
  store_id: number;
  store_name: string;
  currency: Currency;
  is_locked: boolean;
  total_amount: number;
  total_convenience_fees: number;
  guest_total: number;
  order_product_quantity: number;
}

export const useGuestCheckoutStoreSummary = ({
  storeId,
  orderId,
  enabled,
}: {
  storeId: number;
  orderId: number;
  enabled;
}) =>
  useFetch<IGuestCheckoutStoreSummary>(
    enabled
      ? `services/guest/store-checkout-summary/${storeId}/order/${orderId}/detail/`
      : undefined,
  );

export const getGuestOrderDetails = ({ orderId }: { orderId: number }) =>
  get<IGuestCartDetail>(`${BASE_URL}services/guest/order/${orderId}/detail/`);

export const useGuestOrderDetails = ({ orderId }: { orderId: number }) =>
  useFetch<IGuestCartDetail>(`services/guest/order/${orderId}/detail/`);

export interface IRecommendedItem {
  id: number;
  store_id: number;
  product_name: string;
  category: ProductCategory;
  description: string;
  price: number;
  price_type: EPriceType;
  quantity: number;
  has_quantity: boolean;
  preorder_by: number;
  image: string;
  requires_approval: boolean;
  subcategory: 'Add-ons';
  external_link: string | null;
  instructions: string | null;
  is_late_checkout: boolean;
  is_early_checkin: boolean;
  product_prices: Array<IProductPrice>;
}
export const useRecommendedItems = ({ orderId }: { orderId: number }) =>
  useFetch<Array<IRecommendedItem>>(`services/guest/order/${orderId}/recommend/`);
