export enum EPriceType {
  STATIC = 'static',
  VARIABLE = 'variable',
  MULTIPLE = 'multiple',
}
export const priceTypeOptions = [
  {
    label: 'Static price',
    value: EPriceType.STATIC,
  },
  {
    label: 'Variable price',
    value: EPriceType.VARIABLE,
  },
  {
    label: 'Multiple prices',
    value: EPriceType.MULTIPLE,
  },
];

export const priceTimeLabelOptions = [
  { label: '09:00', value: '09:00:00' },
  { label: '10:00', value: '10:00:00' },
  { label: '11:00', value: '11:00:00' },
  { label: '12:00 (noon)', value: '12:00:00' },
  { label: '13:00', value: '13:00:00' },
  { label: '14:00', value: '14:00:00' },
  { label: '15:00', value: '15:00:00' },
  { label: '16:00', value: '16:00:00' },
  { label: '17:00', value: '17:00:00' },
  { label: '18:00', value: '18:00:00' },
];
