import React from 'react';
import clsx from 'clsx';
import Svg from 'react-inlinesvg';
import { Card, CardContent, CardHeader, Typography } from 'ui-kit';
import './CardListItem.scss';
import { Avatar, Divider } from 'antd';

export interface CardItemProps<T extends { id: T['id'] }> {
  id: T['id'];
  title: string;
  subheader?: string;
  footer?: React.ReactNode;
  description: string;
  preview?: string;
  icon?: string;
  image?: string;
  onChange: (id: T['id']) => void;
  isSelected: boolean;
  trimDescription?: boolean;
  dividerText?: string;
}

const BASE_CLASS = 'CardListItem';

function CardListItem<T extends { id: T['id'] }>({
  id,
  title,
  subheader,
  description,
  preview,
  icon,
  image,
  isSelected,
  onChange,
  trimDescription,
  dividerText,
}: CardItemProps<T>) {
  return (
    <>
      <Card
        className={clsx(BASE_CLASS, {
          [`${BASE_CLASS}-selected`]: isSelected,
          [`${BASE_CLASS}-own-product`]: !id,
        })}
        onClick={() => onChange(id)}
      >
        <div className={`${BASE_CLASS}-image__container`}>
          {icon && (
            <div className={`${BASE_CLASS}-image__content`}>
              <Svg
                src={icon}
                color="currentColor"
                className={`${BASE_CLASS}-image__content-icon`}
              />
            </div>
          )}
          {image && <Avatar className={`${BASE_CLASS}-image__content-image`} src={image} />}
        </div>
        <div className={`${BASE_CLASS}-content`}>
          <CardHeader
            title={title}
            subheader={
              subheader ?? (
                <Typography
                  variant="body2"
                  className={`${BASE_CLASS}-content__description`}
                  {...(trimDescription ? { rows: 2 } : {})}
                >
                  {description}
                </Typography>
              )
            }
          />
          {subheader && description && (
            <CardContent>
              <Typography variant="body2" {...(trimDescription ? { rows: 2 } : {})}>
                {preview}
              </Typography>
            </CardContent>
          )}
        </div>
      </Card>
      {!id && (
        <Divider>
          <Typography variant="caption" color="textGray">
            {dividerText || 'OR'}
          </Typography>
        </Divider>
      )}
    </>
  );
}

export default CardListItem;
