import React, { useMemo } from 'react';
import { ProductSubcategory } from 'api/types/product';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import CardList from '../CardList';
import { subcategories } from '../SelectSubcategory/utils';
import { parseSuggestedProductsToCardItems, suggestedProductsData } from './utils';
import { NewProductMode } from '../NewProductStepper';
import { Row, Col } from 'antd';
import SelectSubcategory from 'components/SelectSubcategory';
import './SelectProduct.scss';

interface Props {
  mode?: NewProductMode;
}

const SelectProduct: React.FC<Props> = ({ mode = 'default' }) => {
  const { control, setValue, watch, unregister } = useFormContext();
  const selectedSubcategory = watch('subcategory');

  const items = useMemo(() => {
    const parseItems = parseSuggestedProductsToCardItems(selectedSubcategory);
    if (mode === 'quick') {
      return parseItems;
    }
    const subcategory = subcategories.find(({ id }) => id === ProductSubcategory.SomethingElse);
    return [
      {
        ...subcategory,
        id: 0,
        subheader: selectedSubcategory,
        title: 'Create your own product',
        preview: '',
        dividerText: 'OR USE A TEMPLATE',
      },
      ...parseItems,
    ];
  }, [selectedSubcategory]);

  const handleProduct = (value: number, field: ControllerRenderProps<FieldValues, 'product'>) => {
    const product = !value
      ? { id: 0, price: 0, subcategory: selectedSubcategory }
      : suggestedProductsData.find(({ id }) => value === id);
    unregister('product');

    setValue(
      field.name,
      value === field.value?.id
        ? null
        : { ...product, price: product.price ? Number(product.price).toFixed(2) : '' },
      { shouldValidate: true },
    );
  };

  return (
    <Row className="SelectProduct" gutter={[0, 24]}>
      {mode === 'quick' && (
        <Col className="SelectProduct-filter" xs={24}>
          <Controller
            control={control}
            name="subcategory"
            render={({ field: { ref: _ref, ...rest } }) => {
              return (
                <SelectSubcategory
                  label="View"
                  {...rest}
                  getOptions={(options) => [
                    { value: null, label: 'All' },
                    ...options.filter(({ value }) => value !== ProductSubcategory.SomethingElse),
                  ]}
                />
              );
            }}
          />
        </Col>
      )}
      <Col xs={24}>
        <Controller
          control={control}
          name="product"
          render={({ field }) => (
            <CardList
              items={items}
              trimDescription
              selectedId={field.value?.id}
              onChange={(value) => handleProduct(value, field)}
            />
          )}
        />
      </Col>
    </Row>
  );
};

export default SelectProduct;
