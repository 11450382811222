import { BASE_URL, post } from 'utils/sdk';
import { ProductCategory, ProductSubcategory } from './types/product';
import { EPriceType } from 'components/NewProductStepper/ProductBaseForm/constants';

export interface IProductPrice {
  id: number | null;
  label?: string;
  price: number;
  arrangement?: number;
  hour_label?: string;
  time_label?: string;
  timeLabel?: string;
  // We need of two types of time label because this interface is used form new and old sdk
  // and for this reason we need to keep both
}
interface IProductCreateProps {
  storeId: number;
  massCreate: boolean;
  name: string;
  category: ProductCategory;
  description: string;
  price: number;
  priceType: EPriceType;
  productPrices: Array<IProductPrice>;
  isEarlyCheckin: boolean;
  isLateCheckout: boolean;
  quantity: number;
  hasQuantity: boolean;
  preorderBy: number;
  image: string;
  alertThreshold?: number;
  requiresApproval?: boolean;
  subcategory?: string;
  externalLink?: string;
  instructions?: string;
  productDelegates?: Array<{
    id: number;
    email: string;
    phoneNumber: string;
    isSalesSmsEnabled: boolean;
    isRequestSmsEnabled: boolean;
    isShowPrice: boolean;
  }>;
  extraFields?: Array<{
    label: string;
  }>;
}

interface IProductUpdateProps {
  productId: number;
  massCreate: boolean;
  massUpdate: boolean;
  name: string;
  category: ProductCategory;
  description: string;
  price: number;
  priceType: EPriceType;
  productPrices: Array<IProductPrice>;
  isEarlyCheckin: boolean;
  isLateCheckout: boolean;
  quantity: number;
  hasQuantity: boolean;
  preorderBy: number;
  image: string | undefined;
  alertThreshold?: number;
  requiresApproval?: boolean;
  subcategory?: string;
  externalLink?: string;
  instructions?: string;
  productDelegates?: Array<{
    id: number;
    email: string;
    phoneNumber: string;
    isSalesSmsEnabled: boolean;
    isRequestSmsEnabled: boolean;
    isShowPrice: boolean;
  }>;
  extraFields?: Array<{
    label: string;
  }>;
}

export const productCreate = ({
  storeId,
  massCreate,
  name,
  category,
  description,
  price,
  priceType,
  productPrices,
  isEarlyCheckin,
  isLateCheckout,
  quantity,
  hasQuantity,
  preorderBy,
  image,
  alertThreshold,
  requiresApproval,
  subcategory,
  externalLink,
  instructions,
  productDelegates,
  extraFields,
}: IProductCreateProps) => {
  const formattedProductDelegates = productDelegates?.map(
    ({ id, email, phoneNumber, isSalesSmsEnabled, isRequestSmsEnabled, isShowPrice }) => ({
      id,
      email,
      phone_number: phoneNumber,
      is_sales_sms_enabled: isSalesSmsEnabled,
      is_request_sms_enabled: isRequestSmsEnabled,
      is_show_price: isShowPrice,
    }),
  );

  return post<{ product_group_id?: number }>(`${BASE_URL}services/product/create/`, {
    store_id: storeId,
    mass_create: massCreate,
    product_name: name,
    category,
    description,
    price: priceType === EPriceType.STATIC ? price : 0,
    price_type: priceType,
    product_prices:
      priceType === EPriceType.MULTIPLE
        ? productPrices.map((item, index) => ({
            ...item,
            arrangement: index,
            time_label: item.timeLabel,
          }))
        : [],
    is_early_checkin: isEarlyCheckin,
    is_late_checkout: isLateCheckout,
    quantity,
    has_quantity: hasQuantity,
    preorder_by: preorderBy,
    image,
    alert_threshold: alertThreshold,
    requires_approval: requiresApproval,
    subcategory,
    external_link: externalLink,
    instructions,
    product_delegates: formattedProductDelegates,
    extra_fields: extraFields,
  });
};

export const productUpdate = ({
  productId,
  massCreate,
  massUpdate,
  name,
  category,
  description,
  price,
  priceType,
  quantity,
  hasQuantity,
  preorderBy,
  image,
  alertThreshold,
  requiresApproval,
  subcategory,
  externalLink,
  instructions,
  productDelegates,
  extraFields,
  productPrices,
  isEarlyCheckin,
  isLateCheckout,
}: IProductUpdateProps) => {
  const formattedProductDelegates = productDelegates?.map(
    ({ id, email, phoneNumber, isSalesSmsEnabled, isRequestSmsEnabled, isShowPrice }) => ({
      id,
      email,
      phone_number: phoneNumber,
      is_sales_sms_enabled: isSalesSmsEnabled,
      is_request_sms_enabled: isRequestSmsEnabled,
      is_show_price: isShowPrice,
    }),
  );

  // The response is part of Product/ Partial<Product> but I want to be explicit
  return post<{
    affiliate_link: string | null;
    category: ProductCategory;
    description: string;
    external_link: string | null;
    id: number;
    image: string;
    instructions: string | null;
    owner_local_product_amount: string;
    owner_local_product_commission: string;
    price: string;
    price_type: EPriceType;
    product_group_id?: number;
    product_name: string;
    store_id: number;
    subcategory: ProductSubcategory;
  }>(`${BASE_URL}services/product/${productId}/update/`, {
    product_name: name,
    category,
    description,
    price: priceType === EPriceType.STATIC ? price : 0,
    price_type: priceType,
    product_prices:
      priceType === EPriceType.MULTIPLE
        ? productPrices.map((item, index) => ({
            ...item,
            arrangement: index,
            time_label: item.timeLabel,
          }))
        : [],
    is_early_checkin: isEarlyCheckin,
    is_late_checkout: isLateCheckout,
    quantity,
    has_quantity: hasQuantity,
    preorder_by: preorderBy,
    image,
    alert_threshold: alertThreshold,
    requires_approval: requiresApproval,
    subcategory,
    external_link: externalLink,
    instructions,
    product_delegates: formattedProductDelegates,
    extra_fields: extraFields,
    mass_create: massCreate,
    mass_update: massUpdate,
  });
};

export const productDelete = ({ productId }: { productId: number }) => {
  return post(`${BASE_URL}services/product/${productId}/delete/`);
};

export const productActiveUpdate = ({
  productId,
  isActive,
}: {
  productId: number;
  isActive: boolean;
}) => {
  return post<{ is_active: boolean }>(`${BASE_URL}services/product/${productId}/update-active/`, {
    is_active: isActive,
  });
};

export const massProductDelete = ({ productId }: { productId: number }) =>
  post(`${BASE_URL}services/product/${productId}/mass-delete/`);

export const createProductGroupAndPropagate = ({ productId }: { productId: number }) =>
  post(`${BASE_URL}services/product/${productId}/propagate/`, {});
